@import "../../core/core.less";

@import "modules/header.less";
@import "modules/footer.less";

.slider {
	position: relative;

	.item {
		position: relative;

		.layer {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;


			.title {
				.reset-list;
				color: @terceary-color;
				font-size: 2.5rem;
				font-weight: 500;
				text-transform: uppercase;
				padding: 1rem;
				background-color: #000000ba;

				@media(max-width: 765px) {
					font-size: 2rem;
				}
			}

			.more {
				.inline-block;
				margin: 2rem 0;
				padding: 1.3rem 4rem;
				background: @primary-color;
				border-radius: 5px;
				color: @terceary-color;
				font-size: 1.8rem;
				.transition;

				&:hover {
					background: darken(@primary-color, 5);
					color: @terceary-color;
					text-decoration: none;
				}

				@media(max-width: 765px) {
					font-size: 1.5rem;
				}
			}
		}
	}

	.slick-dots {
		bottom: 4rem;

		@media(max-width: 480px) {
			bottom: 2rem;
		}

		li {
			.inline-block;
			margin: .1rem .5rem;
			width: auto;
			height: auto;
			text-align: right;

			button {
				.reset-list;
				.inline-block;
				width: 20px;
				height: 3px;
				background: @seventernary-color;

				&:before {
					display: none;
				}
			}

			&.slick-active {

				button  {
					width: 50px;
					background: @quartenary-color;
				}
			}
		}
	}

	@media(min-width: 765px) {
		.slick-dots {		
			position: absolute;
			top: 50%;
			bottom: auto;
			right: 1.5rem;
			width: 50px;
			transform: translateY(-50%);

			li {
				display: block;
				margin: .3rem 0;
				width: auto;
				height: auto;
				text-align: right;

				button {
					.reset-list;
					.inline-block;
					width: 75%;
					height: 3px;
					background: @seventernary-color;

					&:before {
						display: none;
					}
				}

				&.slick-active {

					button  {
						width: 100%;
						background: @quartenary-color;
					}
				}
			}
		}
	}
}

.solutions {
	position: relative;
	padding: 6rem 0;
	background: @primary-color;

	article {
		margin: 2rem 0;
		padding: 3rem 2rem;
		padding-bottom: 16em;
		height: 100%;
		max-height: 500px;
		overflow: hidden;
		background: @terceary-color;
		text-align: center;

		img {
			margin: 1.5rem 0;
		}

		.title {
			.reset-list;
			margin: 2rem 0;
			font-size: 1.7rem;
			font-weight: 400;
			color: @fourteenternary-color;
		}

		.more {
			.inline-block;
			position: absolute;
			left: 0;
			right: 0;
			max-width: 150px;
			bottom: 6rem;
			margin: 0 auto;
			padding: 1rem 3rem;
			background: @quartenary-color;
			color: @terceary-color;
			text-align: center;
			font-weight: 600;
			.transition;
		}

		&:hover {
			a {
				text-decoration: none;
			}

			.more {
				background: darken(@quartenary-color, 5);
			}
		}

	}

	.col-full {

		article {
			@media(max-width: 992px) and (min-width: 765px){
				max-height: 400px;
			}
		}
	}
}

.differentials {
	position: relative;
	padding: 2.5rem 0;
	background: @terceary-color;

	h2.title-pg{
		width: 100%;
		height: auto;
		float: left;
		margin: 0;
		text-align: center;
		font-size: 15pt;
		color: #00aeef;
		margin-bottom: 20px;
	}

	.title {
		font-size: 2.5rem;
		color: @fifteenternary-color;
		text-transform: uppercase;
	}

	.text {
		color: @fifteenternary-color;
	}
}

.video {
	position: relative;

	video {
		display: block;
		width: 100%;
		height: auto;
	}

	.layer {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: fade(@sixternary-color, 60);

		.text {
			color: @terceary-color;
			font-size: 2.5rem;
			text-align: center;
			text-transform: uppercase;
		}
	}
}

.dealer{
	margin: 4rem 0;

	h1{
		color: #ccc;
		text-align: center;
		font-size: 2.4rem;
	}

	item{
		height: 100px;
		display: flex!important;
		align-items: center;
		justify-content: center; 
	}
}

.budgets {
	position: relative;
	padding: 6rem 0;
	background: @seventernary-color;

	.title {
		font-size: 3.5rem;
		color: @fifteenternary-color;
	}

	.form-row {
		margin: 1rem 0;

		input,
		select,
		textarea {
			width: 100%;
			padding: 2.3rem;
			border: 1px solid darken(@seventernary-color, 1);
			background: @terceary-color;
			color: fade(@sixternary-color, 90);

			&::-webkit-input-placeholder {
				color: fade(@sixternary-color, 90);
				opacity: 1;
			}

			&::-moz-placeholder { 
				color: fade(@sixternary-color, 90);
				opacity: 1;
			}

			&:-ms-input-placeholder { 
				color: fade(@sixternary-color, 90);
				opacity: 1;
			}

			&:-moz-placeholder { 
				color: fade(@sixternary-color, 90);
				opacity: 1;
			}

		}



		button {
			border: 0;
			padding: 1.8rem 5.5em;
			background: @primary-color;
			border-radius: 5px;
			color: @terceary-color;
			font-size: 1.6rem;
			.transition;

			&:hover {
				background: darken(@primary-color, 10);
			}
		}
	}


}

.next-samsung{
	img{
		margin-left: 183px;
		margin-right: 183px;

		margin-top: 25px;
	} 

}
@font-face {
	font-family: 'Roboto';
	src: url('fonts/Roboto-Thin.eot');
	src: url('fonts/Roboto-Thin.eot?#iefix') format('embedded-opentype'),
		url('fonts/Roboto-Thin.woff') format('woff'),
		url('fonts/Roboto-Thin.ttf') format('truetype');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'Roboto';
	src: url('fonts/Roboto-MediumItalic.eot');
	src: url('fonts/Roboto-MediumItalic.eot?#iefix') format('embedded-opentype'),
		url('fonts/Roboto-MediumItalic.woff') format('woff'),
		url('fonts/Roboto-MediumItalic.ttf') format('truetype');
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: 'Roboto';
	src: url('fonts/Roboto-Bold.eot');
	src: url('fonts/Roboto-Bold.eot?#iefix') format('embedded-opentype'),
		url('fonts/Roboto-Bold.woff') format('woff'),
		url('fonts/Roboto-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Roboto';
	src: url('fonts/Roboto-BlackItalic.eot');
	src: url('fonts/Roboto-BlackItalic.eot?#iefix') format('embedded-opentype'),
		url('fonts/Roboto-BlackItalic.woff') format('woff'),
		url('fonts/Roboto-BlackItalic.ttf') format('truetype');
	font-weight: 900;
	font-style: italic;
}

@font-face {
	font-family: 'Roboto';
	src: url('fonts/Roboto-Medium.eot');
	src: url('fonts/Roboto-Medium.eot?#iefix') format('embedded-opentype'),
		url('fonts/Roboto-Medium.woff') format('woff'),
		url('fonts/Roboto-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Roboto';
	src: url('fonts/Roboto-Regular.eot');
	src: url('fonts/Roboto-Regular.eot?#iefix') format('embedded-opentype'),
		url('fonts/Roboto-Regular.woff') format('woff'),
		url('fonts/Roboto-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Roboto';
	src: url('fonts/Roboto-Black.eot');
	src: url('fonts/Roboto-Black.eot?#iefix') format('embedded-opentype'),
		url('fonts/Roboto-Black.woff') format('woff'),
		url('fonts/Roboto-Black.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Roboto';
	src: url('fonts/Roboto-LightItalic.eot');
	src: url('fonts/Roboto-LightItalic.eot?#iefix') format('embedded-opentype'),
		url('fonts/Roboto-LightItalic.woff') format('woff'),
		url('fonts/Roboto-LightItalic.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'Roboto';
	src: url('fonts/Roboto-Italic.eot');
	src: url('fonts/Roboto-Italic.eot?#iefix') format('embedded-opentype'),
		url('fonts/Roboto-Italic.woff') format('woff'),
		url('fonts/Roboto-Italic.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'Roboto';
	src: url('fonts/Roboto-BoldItalic.eot');
	src: url('fonts/Roboto-BoldItalic.eot?#iefix') format('embedded-opentype'),
		url('fonts/Roboto-BoldItalic.woff') format('woff'),
		url('fonts/Roboto-BoldItalic.ttf') format('truetype');
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: 'Roboto';
	src: url('fonts/Roboto-Light.eot');
	src: url('fonts/Roboto-Light.eot?#iefix') format('embedded-opentype'),
		url('fonts/Roboto-Light.woff') format('woff'),
		url('fonts/Roboto-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Roboto';
	src: url('fonts/Roboto-ThinItalic.eot');
	src: url('fonts/Roboto-ThinItalic.eot?#iefix') format('embedded-opentype'),
		url('fonts/Roboto-ThinItalic.woff') format('woff'),
		url('fonts/Roboto-ThinItalic.ttf') format('truetype');
	font-weight: 100;
	font-style: italic;
}

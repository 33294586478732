.budgets {
	position: relative;
	padding: 6rem 0;
	background: @seventernary-color;

	.title {
		font-size: 3.5rem;
		color: @fifteenternary-color;
	}

	.form-row {
		margin: 1rem 0;

		input,
		select,
		textarea {
			width: 100%;
			padding: 2.3rem;
			border: 1px solid darken(@seventernary-color, 1);
			background: @terceary-color;
			color: fade(@sixternary-color, 90);

			&::-webkit-input-placeholder {
			  color: fade(@sixternary-color, 90);
			  opacity: 1;
			}

			&::-moz-placeholder { 
			  color: fade(@sixternary-color, 90);
			  opacity: 1;
			}

			&:-ms-input-placeholder { 
			  color: fade(@sixternary-color, 90);
			  opacity: 1;
			}

			&:-moz-placeholder { 
			  color: fade(@sixternary-color, 90);
			  opacity: 1;
			}

		}

		button {
			border: 0;
			padding: 1.8rem 5.5em;
			background: @primary-color;
			border-radius: 5px;
			color: @terceary-color;
			font-size: 1.6rem;
			.transition;

			&:hover {
				background: darken(@primary-color, 10);
			}
		}
	}
}

footer {
	position: relative;
	padding: 2rem 0;
	background: @eightternary-color;
	
	.text {
		color: @terceary-color;
		font-size: 1.6rem;
	}
}
@keyframes WaveWhatsApp {
	0%{ transform: scale(0.5); } 
	50%{ opacity: 1; } 
	100%{ transform: scale(1.2); opacity: 0; }
}

.box-whatsapp-fixed {
	position: fixed;
    z-index: 9999;
    bottom: 3rem;
    left: 3rem;
    background-color: #74d626;
    border-radius: 23.5px;
    float: right;
    width: 40px;
    padding-top: 0.9rem;
    height: 40px;
    margin-left: 5px;
    font-size: 15px;
    text-align: center;

	i{
		color: #fff;
	}

	&::after,&::before{
		content: '';
	    position: absolute;
	    opacity: 0;
	    border: 3px solid #74d626;
	    left: -6px;
	    top: -6px;
	    right: -6px;
	    bottom: -6px;
	    border-radius: 52%;
	    animation: WaveWhatsApp 1.5s ease infinite;
	}
}

header {
	position: relative;

	.nav {
		position: relative;
		padding: 1.5rem 0;
		background: @primary-color;

		.phones {
			.reset-list;
			float: left;


			li {
				.inline-block;
				list-style: none;
				color: @terceary-color;
				font-size: 2rem;
				font-weight: 600;

				& > * {
					.inline-block;
					vertical-align: middle;
				} 

				span {
					font-size: 1.6rem;
					font-weight: 500;
				}

				.fa {
					margin-right: .5rem;
					font-size: 2rem;
				}

				a {
					display: block;					
					color: @terceary-color;
				}
			}
		}

		.social-networks {
			.reset-list;
			float: right;

			li {
				.inline-block;
				list-style: none;
				margin: 0 .5rem;
				color: @terceary-color;

				a {
					display: block;
					width: 30px;
					height: 30px;
					border-radius: 50%;
					background: @terceary-color;
					color: @primary-color;
					font-size: 2rem;
				    text-align: center;
				    padding-top: .15rem;

				    &:hover {
				    	background: @quartenary-color;
				    	color: @terceary-color;
				    }
				}

				&:last-child {
					margin-right: 0;
				}
			}
		}

		.links {
			.reset-list;
			float: right;
			margin-left: 1rem;

			li {
				.inline-block;
				list-style: none;

				a {
					display: block;
					padding: .5rem 2rem;
					background: @quartenary-color;
					border-radius: 5px;
					color: @terceary-color;

					&:hover {
						background: @terceary-color;
						color: @primary-color;
						text-decoration: none;
					}
				}
			}
		}

		.links,
		.social-networks {
			.inline-block;
			vertical-align: middle;
		}

	}

	.content-logo {
		margin-top: 3.5rem;
		margin-bottom: 2rem;
	}

	.nav-menu {
		text-align: right;

		& > ul {
			.reset-list;

			& > li {
				.inline-block;
				position: relative;
				list-style: none;
				text-align: center;

				&  > a {
					display: block;
					padding: 5rem 1.1rem;
					color: @tenternary-color;
					font-size: 1.6rem;
					.transition;					

					@media(max-width: 1200px) {
						padding: 5rem .6rem;
						font-size: 1.4rem;
					}

					@media(max-width: 992px) {
						padding: 0;
						font-size: 1.8rem;
					}
				}

				& > a.active {
					background: @quartenary-color;
					color: @terceary-color;
					text-decoration: none;
				}

				&:hover {

					@media(min-width: 992px) {
						& > a,
						& > a.active {
							background: @quartenary-color;
							color: @terceary-color;
							text-decoration: none;
						}

						.sub-menu {
							display: block;
						}
					}
				}
			}
		}

		.sub-menu {
			display: none;
			position: absolute;
			top: 100%;
			width: 250px;
			padding: 1rem;
			background: @quartenary-color;
			z-index: 5;

			ul {
				.reset-list;

				li {
					display: block;
					margin: .8rem 0;
					list-style: none;

					a {
						color: @terceary-color;

						@media(max-width: 1200px) {
							font-size: 1.4rem;
						}
					}
				}
			}
		}

		@media(max-width: 992px) {
			position: fixed;
			top: 0;
			right: -400px;
			width: 300px;
			height: 100%;
			padding: 2rem;
			background: @primary-color;
			overflow-y: auto;
			z-index: 10;
			.transition;


			& > ul {

				& > li {
					display: block;
					margin: 1rem 0;
					text-align: left;

					& > a {
						display: block;
						padding: .5rem;
						color: @terceary-color;

						&:hover,
						&.active {
							background: none;
							text-decoration: none;
						}						
					}

					&.active-submenu {
						.sub-menu {
							display: block;
							background: darken(@primary-color, 5);
						}
					}
				}
			}

			.sub-menu {
				position: relative;
			}
		}

		.btn-close {
			position: absolute;
			top: 0;
			left: 0;
			border: 0;
			padding: .5rem 1rem;
			background: @terceary-color;
			color: @primary-color;
			font-size: 2rem;
		}
	}

	.btn-menu {
		padding: 1rem 2rem;
		border: 0;
		background: @primary-color;
		color: @terceary-color;
		font-size: 2rem;
	}
}

body.navIsActive {

	@media(max-width: 992px) {
		.nav-menu {
			right: 0;
			padding-top: 4rem;
		}
	}
}